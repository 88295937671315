@import "../../_globalColor";

.meter {
  height: 20px;
  position: relative;
  background: $progressBarBackground;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
}

.meter > span {
  display: block;
  height: 100%;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: $progressBarSpanBG;
  position: relative;
  overflow: hidden;
}

.skill {
  line-height: 3.5vw;
}

.skill-bars {
  font-size: 28px;
  width: 40%;
}

.skills-bar-new {
  font-size: 24px;
  padding: 0px 40px;
  width: 100%;
}

.skills-bar-new > h1 {
  margin: 2px 0px 20px 0px;
}

.skills-container {
  display: flex;
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: 1rem;
}

.skills-container > * {
  flex: 1;
  margin-bottom: 30px;
}

.skills-image > img {
  margin-left: 80px;
  max-width: 90%;
  height: 100%;
  margin-top: 15px;
}

.programmingLanguages {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 50px;
  padding: 0 30px;
}

@media (max-width: 1456px) {
  .skills-bar {
    line-height: 4rem;
  }

  .skills-bar-new {
    line-height: 4rem;
  }

  .skills-image {
    display: block;
    order: 2;
  }

  .skills-image > img {
    margin-left: 50%;
    margin-top: 0px;
    transform: translateX(-50%);
  }
}

@media (max-width: 768px) {
  .skills-container {
    flex-direction: column;
    margin-top: 0;
  }

  .skills-bar {
    line-height: 3rem;
    margin: 20px;
  }

  .skills-bar-new {
    line-height: 3rem;
    width: 80%;
    margin-bottom: 35px;
  }

  .skill {
    line-height: 6.5vw;
  }

  .programmingLanguages {
    padding: 0;
  }

  .skills-image {
    display: block;
    order: 2;
    margin-top: 30px;
  }

  .skills-image > img {
    margin-left: 50%;
    transform: translateX(-50%);
  }

  .programmingLanguages {
    display: flex;
    flex-direction: column;
  }

  .skills-bar-new > .skill > p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 900px) and (min-width: 600px) {
  .skills-image > img {
    margin-top: 25%;
  }

  .skills-bar-new {
    padding: 0 10px;
    width: 90%;
    margin: 15px auto;
  }
}