@import "../../_globalColor";

.footer-text {
  text-align: center;
  color: $subTitle !important;
}

.dark-mode {
  color: $textColorDark !important;
}

.footer-div {
  margin-top: 2rem;
  padding-bottom: 1rem;
}

.footer-small-text {
  font-size: 0.1rem;
  margin: 0;
}

.footer-light-text {
  color: #fdfdfd !important;
}

.footer-dark-text {
  color: #171c28 !important;
}