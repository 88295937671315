@import "../_globalColor";

.dark-mode {
  background-color: $darkBackground;
  color: white;
  transition: 0.1s ease-in-out;
}

::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-track {
  background: #f0f0f0;
}

::-webkit-scrollbar-thumb {
  background: #555;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

* {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}