@import "../../_globalColor";

.card-image {
  height: 200px;
}

.card-title {
  color: $titleColor;
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
  margin: 15px 0 0 0;
}

.card-subtitle {
  color: $cardSubtitle;
  font-size: 17px;
  line-height: 1.5rem;
}

.card-courseby {
  color: $cardSubtitle;
  font-size: 20px;
  margin: 10px 0px 0px 0px;
}

.certificate-card {
  background-color: $lightBackground2;
  box-shadow: $lightBoxShadowDark 10px 0px 30px -20px;
  padding: 5px;
  margin: 10px 0px;
  padding-top: 35px;
  border-radius: 10px;
  border: 1px solid $lightBorder1;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.certificate-detail-div {
  text-align: center;
}

.certificate-card:hover {
  box-shadow: $lightBoxShadowDark 0px 20px 30px -10px;
}

.dark-mode.certificate-card:hover {
  box-shadow: $achievementCertificateCardHoverDark 0px 20px 30px -10px;
}

.certificate-image-div {
  height: 220px;
  justify-content: center;
  overflow: hidden;
  text-align: center;
}

.achievement-cards-div {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem 1rem;
}

.certificate-card-footer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.certificate-card-footer span.certificate-tag {
  background: $buttonColor;
  color: $lightBackground3;
  vertical-align: middle;
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 0.75rem;
  height: 2em;
  justify-content: center;
  white-space: nowrap;
  line-height: 1.5;
  margin: 0 0.5rem 0.5rem 0;
  padding: 0 0.75em;
  cursor: pointer;
  transition: 0.2s ease-in;
}

span.certificate-tag:hover {
  background: $buttonHover;
}

@media (max-width: 1380px) {
  .achievement-cards-div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem 1rem;
  }
}

@media (max-width: 900px) {
  .achievement-cards-div {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem 1rem;
  }
}

@media (max-width: 425px) {
  .card-image {
    height: 150px;
  }

  .certificate-image-div {
    height: 160px;
  }
}

@media (max-width: 350px) {
  .certificate-card {
    padding-left: 0;
    padding-right: 0;
  }

  .certificate-detail-div {
    padding: 0 5px;
  }

  .achievement-cards-div {
    display: flex;
    flex-direction: column;
  }
}