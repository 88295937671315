@import "../../_globalColor";

.experience-card {
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin: 10px 0px;
  padding-top: 35px;
}

.experience-text-details {
  margin-bottom: 10px;
}

.experience-card-left {
  width: 20%;
}

.experience-card-left > img {
  object-fit: cover;
  width: 9rem;
  height: 9rem;
  border-radius: 999px;
  padding: 25px;
}

.experience-title-name-duration {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 15px;
}

.experience-card:hover + .experience-card-border {
  border-color: $educationCardBorder;
  width: 100%;
  -webkit-transition: width 0.6s ease-in-out;
  -moz-transition: width 0.6s ease-in-out;
  -o-transition: width 0.6s ease-in-out;
  transition: width 0.6s ease-in-out;
}

.experience-card-border {
  height: 30px;
  width: 80%;
  border-top: 2px solid $lightBorder1;
  margin-bottom: 20px;
  -webkit-transition: width 0.6s ease-in-out;
  -moz-transition: width 0.6s ease-in-out;
  -o-transition: width 0.6s ease-in-out;
  transition: width 0.6s ease-in-out;
}

.experience-head {
  display: flex;
  flex-direction: row;
}

.experience-text-company {
  margin: 0;
  font-size: 28px;
  line-height: 32px;
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.experience-roundedimg {
  object-fit: cover;
  margin-left: 10%;
  width: 80%;
  height: auto;
  box-shadow: 0 0.5rem 1rem $lightBoxShadowDarker;
  background-color: white;
}

.experience-card-right {
  width: 80%;
}

.experience-text-subHeader {
  color: $textColor;
  font-weight: 700;
  font-size: 19px;
  margin: 0px;
  padding-top: 0.8rem;
  line-height: normal;
}

.experience-blackborder {
  border: 1px solid black;
  border-radius: 10px;
}

.experience-whiteborder {
  border: 1px solid $lightBorder1;
  border-radius: 10px;
}

.experience-text-duration {
  color: $titleColor;
  font-size: 16px;
  line-height: 32px;
  margin: 0px;
  padding: 0px;
  font-style: italic;
}

.experience-text-desc {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-weight: 800;
}

.experience-text {
  margin: 0.5rem 0px 12px 0px;
  text-align: left;
}

.experience-description {
  padding: 5px 0px; 
  margin: 0px
}

@media (max-width: 1380px) {
  .experience-text-duration {
    font-size: 10px;
  }

  .experience-title-name-duration {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 14px;
  }

  .experience-text-desc {
    font-size: 18px;
    margin: 0.5rem 0px;
    font-weight: 800;
  }

  .experience-card-right {
    margin-left: 10px;
    width: 100%;
  }

  .experience-text-subHeader {
    padding-top: 0.5rem;
    font-size: 16px;
  }

  .experience-text-company {
    font-size: 20px;
    line-height: 32px;
  }

  .experience-roundedimg {
    width: 90%;
    height: auto;
    margin-bottom: 0.5rem;
    margin-right: 0px;
  }
}

@media (max-width: 1078px) {
  .experience-card-left > img {
    object-fit: cover;
    width: 6rem;
    height: 6rem;
    border-radius: 999px;
    padding: 15px;
  }
}

@media (max-width: 750px) {
  .experience-title-name-duration {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-right: 10px;
  }

  .experience-text-company {
    font-size: 18px;
    line-height: 32px;
  }

  .experience-text-desc {
    font-size: 15px;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-weight: 800;
  }

  .experience-text-subHeader {
    padding-top: 0.5rem;
    font-size: 14px;
  }

  .experience-card {
    display: flex;
    flex-direction: column;
  }

  .experience-card-left {
    width: 100%;
  }

  .experience-card-right {
    width: 100%;
    text-align: center;
    margin: 0;
  }

  .experience-card-border {
    width: 100%;
  }

  .experience-roundedimg {
    width: 30%;
    height: auto;
    display: block;
    margin: 10px auto 1rem;
  }

  .experience-text-bullets > ul {
    max-width: 80%;
    margin: auto;
  }

  .experience-text {
    margin: 0.5rem 0px 12px 0px;
    text-align: left;
  }
}

@media only screen and (max-width: 900px) and (min-width: 600px) {
  .experience-card-left {
    margin-right: 20px;
  }
}