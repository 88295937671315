@import "../../_globalColor";

.card-title {
  color: $titleColor;
  font-size: 22px;
  line-height: 24px;
  font-weight: 700;
  margin: 15px 0 0 0;
}

.card-subtitle {
  color: $cardSubtitle;
  font-size: 17px;
  line-height: 1.5rem;
}

.project-card {
  background-color: $lightBackground2;
  padding: 10px;
  margin: 10px 0px;
  padding-top: 35px;
  border-radius: 10px;
  border: 1px solid $lightBorder1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.project-card-light {
  box-shadow: $lightBoxShadowDark 10px 0px 30px -20px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.project-card-dark {
  box-shadow: $lightBoxShadowDark 0px 10px 30px -15px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.25s ease;
}

.project-detail {
  text-align: center;
}

.project-card-light:hover {
  box-shadow: $lightBoxShadowDark 0px 20px 30px -10px;
}

.project-card-dark:hover {
  box-shadow: $achievementCertificateCardHoverDark 0px 20px 30px -10px;
}

.project-image {
  position: relative;
  overflow: hidden;
  text-align: center;
}

.projects-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem 1rem;
}

#projects {
  margin-top: 4rem;
}

.project-card-footer span.project-tag {
  background: $buttonColor;
  color: $lightBackground3;
  vertical-align: middle;
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 0.75rem;
  height: 2em;
  justify-content: center;
  white-space: nowrap;
  line-height: 1.5;
  margin: 0 0.5rem 0.5rem 0;
  padding: 0 0.75em;
  cursor: pointer;
  transition: 0.2s ease-in;
}

.feature_item {
  text-align: left;
}

.moreProjectsButton {
  margin-top: 40px;
  display: flex;
  gap: 40px;
  justify-content: center;
}

.moreProjectsButton a.main-button {
  margin: 10px 0 0;
}

@media (max-width: 1380px) {
  .projects-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem 1rem;
  }
}

@media (max-width: 900px) {
  .projects-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem 1rem;
  }
}

@media (max-width: 750px) {
  #projects {
    margin-top: 3rem;
  }

  .project-card-footer span.project-tag {
    margin: 0.5rem 0;
  }
}

@media (max-width: 350px) {
  .project-card {
    padding-left: 0;
    padding-right: 0;
  }

  .project-detail {
    padding-left: 8px;
    padding-right: 8px;
  }

  .projects-container {
    display: flex;
    flex-direction: column;
  }
}