@import "../../_globalColor";

#topButton {
  visibility: hidden;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: $buttonColor;
  color: white;
  cursor: pointer;
  padding: 12px;
  border-radius: 15px;
  font-size: 20px;
}

#topButton:hover {
  background-color: $topButtonHover;
  transition: all ease-in-out 0.2s;
}

@media (max-width: 350px) {
  #topButton {
    font-size: 19px;
    padding: 10px;
  }
}