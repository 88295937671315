@font-face {
  font-family: "Agustina Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Agustina Regular"), url("./assets/fonts/Agustina.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"), url("./assets/fonts/Montserrat-Regular.ttf") format("woff");
  font-display: swap;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  scroll-behavior: smooth;
}

.react-reveal {
  opacity: 1 !important;
}

body {
  margin: 0;
  font: 19px / 23px Montserrat, "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

@media (max-width: 1380px) {
  html,
  body {
    font-size: 16px;
    line-height: normal;
    overflow-x: hidden;
  }
}

#preloader {
  background: #171c28 url("./assets/images/loader.gif") no-repeat center center;
  background-size: 15%;
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 100;
}